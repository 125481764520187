import React from "react";
import { graphql, Link } from "gatsby";
import { StaticImage, GatsbyImage } from "gatsby-plugin-image";
import Menu from "../components/common/Menu";
import "../css/movieTheater.css";

const MovieTheaterText = ({ data }) => {
  const movieTheaterTemp = data.sanityPage.glProductionTemp;
  return (
    <div className="movie_theater movietheater_page_gl_production">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-6">
            <Link to="/movie-theater">
              <GatsbyImage
                image={movieTheaterTemp.back.asset.gatsbyImageData}
                alt={movieTheaterTemp.back.asset.altText || "back to home"}
                loading="eager"
                className="img-fluid movie_back_stuff"
              />
            </Link>
          </div>
          <div className="col-lg-6 col-md-6 col-6">
            {/*Burger Menu Start-*/}
            <Menu />
            {/*Burger Menu End-*/}
          </div>
        </div>
        <div className="clearfix movie_tatoo_2row"> </div>
        <br />
        <br />
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <center>
              <GatsbyImage
                image={movieTheaterTemp.description.asset.gatsbyImageData}
                alt={movieTheaterTemp.description.asset.altText || "Main Text"}
                className="img-fluid movie_gl_production_tatto2"
                loading="eager"
                objectFit="contain"
              />
              <br />
            </center>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <center>
              <GatsbyImage
                image={movieTheaterTemp.stay.asset.gatsbyImageData}
                alt={movieTheaterTemp.stay.asset.altText || "Stay Tuned"}
                className="img-fluid movie_gl_production_tatto3"
                loading="eager"
                placeholder="blurred"
              />
              <br />
            </center>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-6"></div>
          <div className="col-lg-6 col-md-6">
            <GatsbyImage
              image={movieTheaterTemp.snail.asset.gatsbyImageData}
              alt={movieTheaterTemp.snail.asset.altText || "Cool"}
              className="img-fluid movie_gl_production_tatto4"
              loading="eager"
              placeholder="blurred"
            />
            <br />
          </div>
        </div>
      </div>
    </div>
  );
};

export const query = graphql`
  query GLProduction($slug: String!) {
    sanityPage(slug: { current: { eq: $slug } }) {
      title
      template
      slug {
        current
      }
      glProductionTemp {
        back {
          asset {
            altText
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: WEBP
            )
          }
        }
        description {
          asset {
            altText
            gatsbyImageData(placeholder: BLURRED, formats: WEBP)
          }
        }
        stay {
          asset {
            altText
            gatsbyImageData(placeholder: BLURRED, formats: WEBP)
          }
        }
        snail {
          asset {
            altText
            gatsbyImageData(placeholder: BLURRED, formats: WEBP)
          }
        }
      }
    }
  }
`;

export default MovieTheaterText;
